*,*::before,*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #00166B; */
}

.body-custom-bg{
  height: 100vh;
  background-image: linear-gradient(rgba(0, 4, 17, 0.8)
  , rgba(0, 4, 17, 0.8)
  ),url(./assets//images/somos1.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'), url(./assets/fonts/gotham/GothamBook.ttf) format('woff');
}
@font-face {
  font-family: 'GothamMedium';
  src: local('GothamMedium'), url(./assets/fonts/gotham/GothamMedium.ttf) format('woff');
}
@font-face {
  font-family: 'GothamBlack';
  src: local('GothamBlack'), url(./assets/fonts/gotham/GothamBlack.otf) format('woff');
}
